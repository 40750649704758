import * as MyEventsContainerActions2 from './actions/my-events-container.actions';
import * as MyEventsEffectsActions from './actions/my-events-effects.actions';
import { myEventsFeature } from './my-events.reducers';

export * as myEventsState from './my-events.state';

const { reducer, name, ...myEventsSelectors } = myEventsFeature;

export {
  reducer as myEventsReducer,
  name as myEventsName,
  MyEventsContainerActions2,
  MyEventsEffectsActions,
  myEventsSelectors,
};
