import { createFeature, createReducer, on } from '@ngrx/store';
import { MyEventsState } from './my-events.state';
import { MyEventsContainerActions2, MyEventsEffectsActions } from './index';
import { toggleValueInArray } from '../../../core/helpers/helpers';

export const initialState: Readonly<MyEventsState> = {
  activeGenreFilters: [],
  activeTagFilters: [],
  activeLocationFilters: [],
  activeAgeRestrictions: [],
  activeEventStatuses: [],
  eventDelete: null,
};

export const myEventsReducers = createReducer(
  initialState,
  on(
    MyEventsContainerActions2.deleteEventClicked,
    (state, { eventDelete }) => ({
      ...state,
      eventDelete,
    }),
  ),
  on(
    MyEventsContainerActions2.deleteEventAbortClicked,
    MyEventsEffectsActions.deleteEventSuccessful,
    MyEventsEffectsActions.deleteEventFailed,
    (state) => ({
      ...state,
      eventDelete: null,
    }),
  ),
  on(MyEventsContainerActions2.toggleGenreFilter, (state, { genreId }) => ({
    ...state,
    activeGenreFilters: toggleValueInArray(state.activeGenreFilters, genreId),
  })),
  on(MyEventsContainerActions2.toggleTagFilter, (state, { tagId }) => ({
    ...state,
    activeTagFilters: toggleValueInArray(state.activeTagFilters, tagId),
  })),
  on(
    MyEventsContainerActions2.toggleLocationFilter,
    (state, { locationId }) => ({
      ...state,
      activeLocationFilters: toggleValueInArray(
        state.activeLocationFilters,
        locationId,
      ),
    }),
  ),
  on(MyEventsContainerActions2.toggleStatusFilter, (state, { status }) => ({
    ...state,
    activeEventStatuses: toggleValueInArray(state.activeEventStatuses, status),
  })),
  on(MyEventsContainerActions2.toggleAgeFilter, (state, { minAge }) => ({
    ...state,
    activeAgeRestrictions: toggleValueInArray(
      state.activeAgeRestrictions,
      minAge,
    ),
  })),
  on(MyEventsContainerActions2.resetFilterButtonClicked, (state) => ({
    ...state,
    activeLocationFilters: [],
    activeGenreFilters: [],
    activeAgeRestrictions: [],
    activeEventStatuses: [],
    activeTagFilters: [],
  })),
  on(MyEventsContainerActions2.resetGenreFiltersClicked, (state) => ({
    ...state,
    activeGenreFilters: [],
  })),
  on(MyEventsContainerActions2.resetTagFiltersClicked, (state) => ({
    ...state,
    activeTagFilters: [],
  })),
  on(MyEventsContainerActions2.resetLocationFiltersClicked, (state) => ({
    ...state,
    activeLocationFilters: [],
  })),
  on(MyEventsContainerActions2.resetStatusFiltersClicked, (state) => ({
    ...state,
    activeEventStatuses: [],
  })),
  on(MyEventsContainerActions2.resetAgeFiltersClicked, (state) => ({
    ...state,
    activeAgeRestrictions: [],
  })),
);

export const myEventsFeature = createFeature({
  name: 'myEvents',
  reducer: myEventsReducers,
});
