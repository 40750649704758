import { createAction, props } from '@ngrx/store';
import { Event } from '../../../../core/models/app.models';

export const toggleGenreFilter = createAction(
  '[My Events Container] Toggled Genre Filter',
  props<{
    genreId: number;
  }>(),
);
export const toggleTagFilter = createAction(
  '[My Events Container] Toggled Tag Filter',
  props<{
    tagId: number;
  }>(),
);
export const deleteEventClicked = createAction(
  '[My Events Container] Delete Event Clicked',
  props<{
    eventDelete: Event;
  }>(),
);
export const deleteEventAbortClicked = createAction(
  '[My Events Container] Delete Event Abort Clicked',
);
export const deleteEventSubmitClicked = createAction(
  '[My Events Container] Delete Event Submit Clicked',
);
export const toggleLocationFilter = createAction(
  '[My Events Container] Toggled Location Filter',
  props<{
    locationId: number;
  }>(),
);
export const toggleStatusFilter = createAction(
  '[My Events Container] Toggled Status Filter',
  props<{
    status: string;
  }>(),
);
export const toggleAgeFilter = createAction(
  '[My Events Container] Toggled Age Filter',
  props<{
    minAge: string;
  }>(),
);
export const resetFilterButtonClicked = createAction(
  '[My Events Container] Reset Filter Button Clicked',
);
export const resetGenreFiltersClicked = createAction(
  '[My Events Container] Reset Genre Filters Clicked',
);
export const resetTagFiltersClicked = createAction(
  '[My Events Container] Reset Tag Filters Clicked',
);
export const resetLocationFiltersClicked = createAction(
  '[My Events Container] Reset Location Filters Clicked',
);
export const resetStatusFiltersClicked = createAction(
  '[My Events Container] Reset Status Filters Clicked',
);
export const resetAgeFiltersClicked = createAction(
  '[My Events Container] Reset Age Filters Clicked',
);
